import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VuePageTitle from 'vue-page-title'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faYoutube, faTumblr, faLine, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faPlay, faArrowRight, faAngleRight, faSearch, faLink, faMapMarkerAlt, faArrowLeft, faLongArrowAltLeft, faCheck, faGraduationCap, faMapMarkedAlt, faEllipsisV, faInfoCircle, faPlus, faAngleDown, faStar, faCheckCircle, faEnvelope, faShare, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import GSignInButton from 'vue-google-signin-button'
import { i18n } from './i18n'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
// import { ValidationProvider, ValidationObserver, localize, localeChanged, extend } from 'vee-validate'
// eslint-disable-next-line
import * as VeeValidate from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import th from 'vee-validate/dist/locale/th.json'
import zh from 'vee-validate/dist/locale/zh_CN.json'
import * as rules from 'vee-validate/dist/rules'
import Notifications from 'vue-notification'
import DisableAutocomplete from 'vue-disable-autocomplete'
import '@/assets/scss/wya.scss'
import GAuth from 'vue-google-oauth2'
import 'vue-search-select/dist/VueSearchSelect.css'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import vSelect from 'vue-select'
// import VuePreview from 'vue-preview'
import ScrollFixedHeader from 'vuejs-scroll-fixed-header'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueSocialSharing from 'vue-social-sharing'
import Multiselect from 'vue-multiselect'
import Meta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueVideoPlayer from 'vue-video-player'
import { Datepicker, Timepicker, DatetimePicker } from '@livelybone/vue-datepicker'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { MonthPickerInput, MonthPicker } from 'vue-month-picker'
const hls = require('videojs-contrib-hls')

Vue.config.productionTip = false

Vue.use(VuePageTitle, {
  prefix: process.env.VUE_APP_TITLE
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(GSignInButton)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Notifications)
Vue.use(vueNumeralFilterInstaller)
Vue.use(DisableAutocomplete)
Vue.use(VueFilterDateFormat)
Vue.component('v-select', vSelect)
Vue.use(ScrollFixedHeader)
Vue.use(VuePlyr)
Vue.use(VueSocialSharing)
Vue.component('multiselect', Multiselect)
Vue.use(Meta)
Vue.use(hls)
Vue.use(VueVideoPlayer)
Vue.component('datepicker', Datepicker)
Vue.component('timepicker', Timepicker)
Vue.component('datetime-picker', DatetimePicker)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(CoolLightBox)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places' // This is required if you use the Autocomplete plugin
  },
  installComponents: true
})

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
// Vue.use(VuePreview, {
//   mainClass: 'pswp--minimal--dark',
//   barsSize: { top: 0, bottom: 0 },
//   captionEl: true,
//   fullscreenEl: false,
//   shareEl: false,
//   bgOpacity: 0.5,
//   tapToClose: true,
//   tapToToggleControls: false
// })

library.add(faFacebookF, faTwitter, faInstagram, faYoutube, faTumblr, faLine, faPlay, faArrowRight, faAngleRight, faSearch,
  faLink, faMapMarkerAlt, faArrowLeft, faLongArrowAltLeft, faCheck, faGraduationCap, faMapMarkedAlt, faEllipsisV, faInfoCircle, faGoogle, faPlus, faAngleDown, faStar, faCheckCircle, faEnvelope, faShare, faEllipsisH)

Vue.component('ValidationProvider', VeeValidate.ValidationProvider)
Vue.component('ValidationObserver', VeeValidate.ValidationObserver)

VeeValidate.localize({ en, th })
VeeValidate.localize('zh', zh)
// Add a rule.
VeeValidate.extend('secret', {
  validate: value => value === 'example',
  message: 'This is not the magic word'
})

Object.keys(rules).forEach(rule => {
  VeeValidate.extend(rule, rules[rule])
})

// with typescript
for (const [rule, validation] of Object.entries(rules)) {
  VeeValidate.extend(rule, {
    ...validation
  })
}
i18n.locale = 'th'
Vue.mixin({
  data () {
    return {
      APP_VERSION: '0.1.0',
      APP_DEFAULT_LANGUAGE: 'en',
      PUBLIC_PATH: process.env.VUE_APP_BASE_PATH,
      dateFormatConfig: {
        monthNames: [
          i18n.t('January'), i18n.t('February'), i18n.t('March'), i18n.t('April'), i18n.t('May'), i18n.t('June'),
          i18n.t('July'), i18n.t('August'), i18n.t('September'), i18n.t('October'), i18n.t('November'), i18n.t('December')
        ],
        monthNamesShort: [
          i18n.t('Jan'), i18n.t('Feb'), i18n.t('Mar'), i18n.t('Apr'), i18n.t('MayS'), i18n.t('Jun'),
          i18n.t('Jul'), i18n.t('Aug'), i18n.t('Sep'), i18n.t('Oct'), i18n.t('Nov'), i18n.t('Dec')
        ]
      }
    }
  },
  methods: {
    setLocale (locale) {
      i18n.locale = locale
      VeeValidate.localeChanged()
    },
    uppercase (text) {
      if (text) {
        return text.toUpperCase()
      } else {
        return text
      }
    }
  }
})

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    th: th,
    en: en,
    zh: zh
  }
})

VeeValidate.extend('verify_password', {
  validate: value => {
    // eslint-disable-next-line
    var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})') //(?=.*[!@#\$%\^&\*])
    return strongRegex.test(value) ? true : i18n.t('validatePassword')
  }
})

router.beforeEach((to, from, next) => {
  const lang = store.getters.currentLanguage
  i18n.locale = lang
  VeeValidate.localize(lang)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'authenLogin'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
