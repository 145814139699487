<template>
  <body id="body-content" :class="bgClass">
    <NavbarSlide></NavbarSlide>
    <Navbar></Navbar>
    <transition :duration="300" origin="center top" mode="out-in">
      <router-view></router-view>
    </transition>
    <Footer />
  </body>
</template>

<script>

import Navbar from '../../components/navbar/Navbar.vue'
import NavbarSlide from '../../components/navbar/NavbarSlide.vue'
import Footer from '../../components/Footer.vue'

export default {
  name: 'BaseLayout',
  components: {
    Navbar,
    NavbarSlide,
    Footer
  },
  data () {
    return {
      bgClass: 'bg-base-layout'
    }
  },
  watch: {
    '$route' () {
      this.getBgClass()
    }
  },
  mounted () {
    this.getBgClass()
    document.querySelector('body#body-main').classList.remove('bm-overlay')
  },
  methods: {
    getBgClass () {
      if (this.$route.path === '/') {
        this.bgClass = 'bg-base-layout'
      } else if (this.$route.path === '/search-directory') {
        /* this.bgClass = 'bg-search-directory' */
        this.bgClass = ''
      } else {
        this.bgClass = ''
      }
    }
  }
}
</script>

<style lang="scss">
  .base-container {
    max-width: 1520px !important;
  }
  .bg-base-layout {
    background-image: url('../../assets/images/banner/banner.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .bg-search-directory {
    background-image: url('../../assets/images/banner/bg_search_directory.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
</style>
