import axios from 'axios'
var apiTimeOut = process.env.VUE_APP_API_TIMEOUT
// var basicClientCredentail = process.env.VUE_APP_BASIC_CLIENT_CREDENTIALS
let isAlreadyFetchingAccessToken = false
var subscribers = []

export default (config) => {
  let configAxios = {
    baseURL: null,
    withCredentials: false,
    timeout: apiTimeOut,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
  }
  configAxios = { ...configAxios, ...config }
  const instance = axios.create(configAxios)
  instance.interceptors.request.use(function (config) {
    if (config.url.search('/api/auth/revoke-token/') < 0) {
      if (config.url.search('/api/auth/token') >= 0) {
        if (config.data.grant_type === 'client_credentials') {
          // config.headers.common.Authorization = 'Basic ' + basicClientCredentail
        }
      } else if (localStorage.getItem('accessToken')) {
        config.headers.common.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
      } else {
        if (config.url.search('/api/user-auth/registration') !== 0) {
          config.headers.common.Authorization = 'Bearer ' + localStorage.getItem('clientCredentialToken')
        }
      }
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  instance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    const url = error.response.config.url
    if (url.search('/api/auth/token/') < 0 && error.response.status === 401) {
      return resetTokenAndReattemptRequest(error.response)
    } else {
      return Promise.reject(error)
    }
  })

  return instance
}

function resetTokenAndReattemptRequest (res) {
  try {
    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(() => {
        res.config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
        resolve(axios(res.config))
      })
    })
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true
      axios.post(res.config.baseURL + '/api/auth/token/', {
        grant_type: 'refresh_token',
        client_id: process.env.VUE_APP_PERSONAL_CLIENT_ID,
        refresh_token: localStorage.getItem('refreshToken')
      }).then(response => {
        localStorage.setItem('accessToken', response.data.access_token)
        localStorage.setItem('refreshToken', response.data.refresh_token)
        onAccessTokenFetched(response.data.access_token)
      }).catch(error => {
        return Promise.reject(error)
      })
    } else {
      // window.location.href = '/'
    }
    return retryOriginalRequest
  } catch (err) {
    return Promise.reject(err)
  }
}

function onAccessTokenFetched (accessToken) {
  subscribers.forEach(callback => callback(accessToken))
  subscribers = []
}

function addSubscriber (callback) {
  subscribers.push(callback)
}
