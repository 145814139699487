import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Api from '../api/api'

Vue.use(Vuex)
axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.timeout = 15000
const currentDate = new Date()

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LANGUAGE,
    currency: localStorage.getItem('currency') || process.env.VUE_APP_DEFAULT_CURRENCY,
    loggedIn: false,
    clientCredentialToken: '',
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    tokenType: localStorage.getItem('tokenType') || null,
    currencies: [
      { code: 'USD', name: 'USD' },
      { code: 'THB', name: 'THB' },
      { code: 'EUR', name: 'EUR' },
      { code: 'INR', name: 'INR' }
    ],
    userInfo: {},
    userProfile: {},
    schoolId: null,
    teacherId: null,
    school: {},
    formSearchDirectory: {
      search_type: 'school',
      school_name: '',
      instructors_name: '',
      traning: '',
      start_date: currentDate,
      end_date: new Date(currentDate.getDate() + 1),
      region: {
        value: null,
        text: null
      },
      style_of_yoga: null
    }
  },
  modules: {
  },
  getters: {
    currentCurrency: state => {
      return state.currency
    },
    currentLanguage: state => {
      return state.language
    },
    clientCredentialToken: () => {
      return localStorage.getItem('clientCredentialToken') || null
    },
    loggedIn: state => {
      if (state.accessToken !== null) {
        return true
      } else {
        return false
      }
    },
    userInfo: state => {
      return state.userInfo
    },
    userProfile: state => {
      if (!state.userProfile.id) {
        var userObj = JSON.parse(localStorage.getItem('userProfile'))
        return userObj
      } else {
        return state.userProfile
      }
    },
    schoolId: state => {
      return localStorage.getItem('schoolId')
    },
    formSearchDirectory: state => {
      return state.formSearchDirectory
    },
    teacherId: state => {
      return localStorage.getItem('teacherId')
    },
    currencies: state => {
      return state.currencies
    }
  },
  mutations: {
    changeCurrency (state, currency) {
      state.currency = currency
    },
    changeLanguage (state, language) {
      state.language = language
    },
    clientCredential (state, token) {
      state.clientCredentialToken = token
    },
    login (state, token) {
      state.accessToken = token.access_token
      state.refreshToken = token.refresh_token
      state.loggedIn = true
    },
    logout (state) {
      state.accessToken = null
      state.refreshToken = null
      state.tokenType = null
      state.loggedIn = false
    },
    userInfo (state, user) {
      state.userInfo = {
        pk: user.pk,
        username: user.username,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name
      }
    },
    userProfile (state, userProfile) {
      state.userProfile = {
        id: userProfile.id,
        email: userProfile.email,
        firstName: userProfile.first_name,
        lastName: userProfile.last_name,
        profile: {
          avatar: userProfile.profile.avatar,
          phoneNumber: userProfile.profile.phone_number,
          webSiteUrl: userProfile.profile.web_site_url,
          detail: userProfile.profile.detail,
          region: userProfile.profile.region,
          regionName: userProfile.profile.region_name,
          createdAt: userProfile.profile.created_at,
          updatedAt: userProfile.profile.updated_at
        }
      }
      localStorage.setItem('userProfile', JSON.stringify(state.userProfile))
    },
    getSchoolDetail (state, school) {
      state.school = school
      state.schoolId = school.id
    },
    formSearchDirectory (state, formSearchDirectory) {
      state.formSearchDirectory = { ...state.formSearchDirectory, ...formSearchDirectory }
    },
    resetFormSearchDirectory (state) {
      state.formSearchDirectory = {
        search_type: 'school',
        school_name: '',
        instructors_name: '',
        traning: '',
        start_date: currentDate,
        end_date: new Date(currentDate.getDate() + 1),
        region: {
          value: null,
          text: null
        },
        style_of_yoga: null
      }
    },
    getMySchool (state, school) {
      localStorage.setItem('schoolId', school.id)
      state.schoolId = school.id
    },
    getTeacher (state, teacher) {
      localStorage.setItem('teacherId', teacher.id)
      state.teacherId = teacher.id
    }
  },
  actions: {
    changeCurrency (context, currency) {
      localStorage.setItem('currency', currency)
      context.commit('changeCurrency', currency)
    },
    changeLanguage (context, language) {
      localStorage.setItem('language', language)
      context.commit('changeLanguage', language)
    },
    clientCredential (context) {
      return new Promise((resolve, reject) => {
        Api.clientCredential()
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('clientCredentialToken', response.data.access_token)
              localStorage.setItem('tokenType', response.data.token_type)
              context.commit('clientCredential', response.data.access_token)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    authenWithSocial (context, payload) {
      return new Promise((resolve, reject) => {
        Api.authenWithSocial(payload)
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('accessToken', response.data.access_token)
              localStorage.setItem('refreshToken', response.data.refresh_token)
              localStorage.setItem('tokenType', response.data.token_type)
              context.commit('login', response.data)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    login (context, credentials) {
      return new Promise((resolve, reject) => {
        Api.login(credentials)
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('accessToken', response.data.access_token)
              localStorage.setItem('refreshToken', response.data.refresh_token)
              localStorage.setItem('tokenType', response.data.token_type)
              context.commit('login', response.data)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout (context) {
      return new Promise((resolve, reject) => {
        Api.logout()
          .then(response => {
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            window.localStorage.removeItem('tokenType')
            window.localStorage.removeItem('teacherId')
            window.localStorage.removeItem('schoolId')
            window.localStorage.clear()
            context.commit('logout')
            resolve(response)
          })
          .catch(error => {
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            window.localStorage.removeItem('tokenType')
            window.localStorage.clear()
            context.commit('logout')
            reject(error)
          })
      })
    },
    userInfo (context) {
      return new Promise((resolve, reject) => {
        Api.userInfo()
          .then(response => {
            if (response.status === 200) {
              context.commit('userInfo', response.data)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    userProfile (context) {
      return new Promise((resolve, reject) => {
        Api.userProfile()
          .then(response => {
            if (response.status === 200) {
              context.commit('userProfile', response.data)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    register (context, payload) {
      return new Promise((resolve, reject) => {
        Api.register(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateUserProfile (context, payload) {
      return new Promise((resolve, reject) => {
        Api.updateUserProfile(payload)
          .then(response => {
            context.commit('userProfile', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getSchoolDetail (context, payload) {
      return new Promise((resolve, reject) => {
        Api.getSchool(payload)
          .then(response => {
            context.commit('getSchoolDetail', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getMySchool (context, data) {
      return new Promise((resolve, reject) => {
        Api.listSchool({ user: data.user })
          .then(async (response) => {
            if (response.data.results) {
              if (response.data.results.length > 0 && response.data.results[0].user === data.user) {
                context.commit('getMySchool', response.data.results[0])
              }
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateFormSearchDirectory (context, data) {
      context.commit('formSearchDirectory', data)
    },
    resetFormSearchDirectory (context) {
      context.commit('resetFormSearchDirectory')
    },
    getTeacher (context, data) {
      return new Promise((resolve, reject) => {
        Api.teacher({ user: data.user })
          .then(async (response) => {
            if (response.data.results.length > 0) {
              context.commit('getTeacher', response.data.results[0])
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
})
