import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseLayout from '../views/layout/BaseLayout.vue'
import AuthLayout from '../views/layout/AuthenLayout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-base-layout'
        },
        component: () => import('../views/Home.vue')
      },
      {
        path: '/search-directory',
        name: 'searchDirectory',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/SearchDirectory.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/About.vue')
      },
      {
        path: '/member-benefit',
        name: 'memberBenefit',
        meta: {
          requiresAuth: false,
          bgClass: ''
        },
        component: () => import('../views/MemberBenefit.vue')
      },
      {
        path: '/about',
        name: 'aboutUs',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/About.vue')
      },
      {
        path: '/legal-agreement',
        name: 'legalAgreement',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/legal/LegalAgreement.vue')
      },
      {
        path: '/legal/terms-and-conditions',
        name: 'termsAndConditions',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/legal/TermsAndConditions.vue')
      },
      {
        path: '/legal/privacy-policy',
        name: 'privacyPolicy',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/legal/PrivacyPolicy.vue')
      },
      {
        path: '/legal/designation-and-trademark',
        name: 'designationAndTrademark',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/legal/DesignationAndTrademark.vue')
      },
      {
        path: '/legal/cancellation-and-refund-policy',
        name: 'cancellationAndRefundPolicy',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/legal/CancellationAndRefundPolicy.vue')
      },
      {
        path: '/about/our-history',
        name: 'ourHistory',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/about/OurHistory.vue')
      },
      {
        path: '/about/our-team',
        name: 'ourTeam',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/about/OurTeam.vue')
      },
      {
        path: '/about/faq',
        name: 'faq',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/about/FAQ.vue')
      },
      {
        path: '/about/contact',
        name: 'contact',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/about/Contact.vue')
      },
      {
        path: '/about/membership-guideline',
        name: 'membershipGuideline',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/about/MembershipGuideline.vue')
      },
      {
        path: '/community/videos/video/:id',
        name: 'video',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/community/video/Video.vue')
      },
      {
        path: '/user-profile',
        name: 'userProfile',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/UserProfile.vue')
      },
      {
        path: '/change-password',
        name: 'changePassword',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/ChangePassword.vue')
      },
      {
        path: '/school/profile',
        name: 'schoolProfile',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolProfile.vue')
      },
      {
        path: '/school/add-event',
        name: 'addEvent',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/AddEvent.vue')
      },
      {
        path: '/school/edit-event/:eventId',
        name: 'editEvent',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/EditEvent.vue')
      },
      {
        path: '/event/:eventId',
        name: 'eventDetail',
        meta: {
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/Event.vue')
      },
      {
        path: '/school/profile/:schoolId',
        name: 'schoolProfileDetail',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolProfile.vue')
      },
      {
        path: '/school/register',
        name: 'schoolRegister',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolRegister.vue')
      },
      {
        path: '/school/register-information',
        name: 'schoolRegisterInformation',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolRegisterInformation.vue')
      },
      {
        path: '/school/register-yaga-and-factulty',
        name: 'schoolRegisterYogaAndFaculty',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolRegisterYogaAndFaculty.vue')
      },
      {
        path: '/school/register-document',
        name: 'schoolRegisterDocument',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolRegisterDocument.vue')
      },
      {
        path: '/school/register-payment',
        name: 'schoolRegisterPayment',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolRegisterPayment.vue')
      },
      {
        path: '/school/register-success',
        name: 'schoolRegisterSuccess',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/school/SchoolRegisterSuccess.vue')
      },
      {
        path: '/teacher/profile',
        name: 'teacherProfile',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherProfile.vue')
      },
      {
        path: '/teacher/profile/:teacherId',
        name: 'teacherProfileDetail',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherProfile.vue')
      },
      {
        path: '/teacher/register',
        name: 'teacherRegister',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherRegister.vue')
      },
      {
        path: '/teacher/register-information',
        name: 'teacherRegisterInformation',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherRegisterInformation.vue')
      },
      {
        path: '/teacher/register-document',
        name: 'teacherRegisterDocument',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherRegisterDocument.vue')
      },
      {
        path: '/teacher/register-payment',
        name: 'teacherRegisterPayment',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherRegisterPayment.vue')
      },
      {
        path: '/teacher/register-success',
        name: 'teacherRegisterSuccess',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherRegisterSuccess.vue')
      },
      {
        path: '/teacher/register-yaga',
        name: 'teacherRegisterYoga',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/teacher/TeacherRegisterYoga.vue')
      },
      {
        path: '/apply-type',
        name: 'applyType',
        meta: {
          requiresAuth: true,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/ApplyType.vue')
      },
      {
        path: '/billing',
        name: 'billing',
        meta: {
          requiresAuth: true,
          bgClass: ''
        },
        component: () => import('../views/billing/Index.vue')
      },
      {
        path: '/billing/checkout/:invoiceId',
        name: 'billingCheckout',
        meta: {
          requiresAuth: true,
          bgClass: ''
        },
        component: () => import('../views/billing/checkout/Index.vue')
      },
      {
        path: '/billing/checkout/payment/:invoiceId',
        name: 'billingCheckoutPayment',
        meta: {
          requiresAuth: true,
          bgClass: ''
        },
        component: () => import('../views/billing/checkout/Payment.vue')
      },
      {
        path: '/billing/checkout/complete/:invoiceId',
        name: 'billingCheckoutComplete',
        meta: {
          requiresAuth: true,
          bgClass: ''
        },
        component: () => import('../views/billing/checkout/Complete.vue')
      },
      {
        path: '/billing/invoice/:invoiceId',
        name: 'billingInvoiceDetail',
        meta: {
          requiresAuth: false,
          bgClass: ''
        },
        component: () => import('../views/billing/Invoice.vue')
      },
      {
        path: '/fee-details',
        name: 'feeDetails',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/FeeDetails.vue')
      },
      {
        path: '/standards',
        name: 'standards',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/Standards.vue')
      },
      {
        path: '/standards/teachers',
        name: 'teachersStandards',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/teacher/TeachersStandards.vue')
      },
      {
        path: '/standards/RSY',
        name: 'RSY',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/RSY.vue')
      },
      {
        path: '/standards/AYS',
        name: 'AYS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/AYS.vue')
      },
      {
        path: '/standards/KYS',
        name: 'KYS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/KYS.vue')
      },
      {
        path: '/standards/PYS',
        name: 'PYS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/PYS.vue')
      },
      {
        path: '/standards/YTS',
        name: 'YTS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/YTS.vue')
      },
      {
        path: '/standards/AVS',
        name: 'AVS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/AVS.vue')
      },
      {
        path: '/standards/YYS',
        name: 'YYS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/YYS.vue')
      },
      {
        path: '/standards/MSS',
        name: 'MSS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/MSS.vue')
      },
      {
        path: '/standards/CYS',
        name: 'CYS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/CYS.vue')
      },
      {
        path: '/standards/EYEP',
        name: 'EYEP',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/EYEP.vue')
      },
      {
        path: '/standards/OYCP',
        name: 'OYCP',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/OYCP.vue')
      },
      {
        path: '/standards/GYS',
        name: 'GYS',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/standards/school/GYS.vue')
      },
      {
        path: '/community/videos',
        name: 'videos',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/community/video/Videos.vue')
      },
      {
        path: '/community/exclusive-center',
        name: 'exclusiveCenter',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/community/ExclusiveCenter.vue')
      },
      {
        path: '/community/exclusive-center/video/:id',
        name: 'exClusiveCenterVideo',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/community/ExclusiveCenterVideo.vue')
      },
      {
        path: '/community/wya-events',
        name: 'wyaEvents',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/community/WYAEvents.vue')
      },
      {
        path: '/community/member-park',
        name: 'memberPark',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/community/MemberPark.vue')
      },
      {
        path: '/feedback',
        name: 'feedback',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/Feedback.vue')
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '/auth/login',
        name: 'authenLogin',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/auth/Login.vue')
      },
      {
        path: '/auth/signup',
        name: 'authenSignup',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/auth/Signup.vue')
      },
      {
        path: '/auth/resetpassword',
        name: 'authenResetpassword',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/auth/ResetPassword.vue')
      },
      {
        path: '/auth/confirm-reset-password/:uid/:token',
        name: 'authenConfirmResetpassword',
        meta: {
          requiresAuth: false
        },
        component: () => import('../views/auth/ConfirmResetPassword.vue')
      },
      {
        path: '/auth/logout',
        name: 'authLogout',
        meta: {
          requiresAuth: false,
          bgClass: 'bg-search-directory'
        },
        component: () => import('../views/auth/Logout.vue')
      },
      {
        path: '/billing/invoice',
        name: 'billingInvoice',
        meta: {
          requiresAuth: false,
          bgClass: ''
        },
        component: () => import('../views/billing/Invoice.vue')
      }
    ]
  },
  {
    path: '*',
    component: BaseLayout,
    children: [
      {
        path: '*',
        name: 'pageNotFound',
        meta: {
          requiresAuth: false,
          bgClass: ''
        },
        component: () => import('../views/PageNotFound.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
