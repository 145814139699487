<template>
  <scroll-fixed-header :fixed.sync="fixed" :threshold="56" class="px-0 container-fluid">
    <b-navbar toggleable="lg" type="light" :class="[navStyle == 'authen' ? 'container-fluid' :'navbar-container nav-shadow container']">
      <b-navbar-brand :to="{name: 'home'}" class="border-none">
        <div v-if="navStyle =='authen' ">
          <span class="nav-link-2">
            <font-awesome-icon icon="arrow-left" /> {{ $t('Home') }}
          </span>
        </div>
        <div v-else>
          <img alt="WYA Yoga" fluid class="d-none d-lg-block d-md-block d-xl-block" src="../../assets/images/logo/logo.png">
          <img alt="WYA Yoga" height="50" class="d-sm-block d-lg-none d-md-none d-xl-none" src="../../assets/images/logo/logo.png">
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" class="border-0 d-none">
         <template>
           <b-icon icon="list" class="text-white"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-col v-if="navStyle =='authen' ">
      </b-col>
      <b-collapse v-else id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-row class="header-text-icon d-block justify-content-right w-100" v-bind:class="{ 'text-white': $route.path == '/'}">
          <b-col cols="12 mb-3">
            <span class="header-mini-bar d-block float-right header-text">
              <small class="mr-2 font-weight-500"><a class="" href="mailto:contact@wyayoga.org" title="Email WYA Yoga Office">contact@wyayoga.org</a></small>
              <small class="mr-2 font-weight-500">|</small>
              <small class="mr-3 font-weight-500"><a class="" href="tel:+91 (0) 962-579-3618" title="Call WYA Yoga Office">+91 (0) 962-579-3618</a></small>
              <a :href="twitterUrl" target="_blank" class="header-text-icon" v-bind:class="{ 'text-white': $route.path == '/'  }"><small class="mr-3"><font-awesome-icon :icon="['fab', 'twitter']"/></small></a>
              <a :href="facebookUrl" target="_blank" class="header-text-icon" v-bind:class="{ 'text-white': $route.path == '/'  }"><small class="mr-3"><font-awesome-icon :icon="['fab', 'facebook-f']"/></small></a>
              <a :href="instagramUrl" target="_blank" class="header-text-icon" v-bind:class="{ 'text-white': $route.path == '/'  }"><small class="mr-3"><font-awesome-icon :icon="['fab', 'instagram']"/></small></a>
              <a :href="youtubeUrl" target="_blank" class="header-text-icon" v-bind:class="{ 'text-white': $route.path == '/'  }"><small><font-awesome-icon :icon="['fab', 'youtube']"/></small></a>
            </span>
          </b-col>
          <b-col cols="12" class="float-right mt-4 mt-md-2 mt-lg-3 mt-xl-3 pr-0">
            <b-navbar-nav class="mr-auto navbar-light float-md-right float-md-right float-lg-right float-xl-right" v-bind:class="{ 'nav-border-0': $route.path !== '/' || $route.path !== '/search-directory' }">
              <li class="nav-item">
                <router-link :to="{name: 'home'}" exact class="nav-link font-weight-500 border-bottom px-3" v-bind:class="{ 'text-white': $route.path == '/'  }">{{ $t('Home') }}</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{name: 'searchDirectory'}" exact class="nav-link font-weight-500 border-bottom px-2" v-bind:class="{ 'text-white': $route.path == '/'  }">{{ $t('Directory') }}</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link :to="{name: 'videos'}" exact class="nav-link font-weight-500 border-bottom px-2" v-bind:class="{ 'text-white': $route.path == '/'  }">{{ $t('Videos') }}</router-link>
              </li> -->
              <!-- <li>
                <b-nav-item-dropdown class="border-bottom" v-bind:class="{ 'text-white': $route.path == '/'  }" :text="$t('Join WYA')">
                  <b-dropdown-item href="/standards-for-schools">{{ $t('Standards For Schools') }}</b-dropdown-item>
                  <b-dropdown-item href="/standards-for-teachers">{{ $t('Standards For Teachers') }}</b-dropdown-item>
                  <b-dropdown-item href="/online-yoga-course-provider">{{ $t('Online Yoga Course Provider (OYCP)') }}</b-dropdown-item>
                  <b-dropdown-item href="/exchange-yoga-experience-program">{{ $t('Exchange Yoga Experience program (EYEP)') }}</b-dropdown-item>
                  <b-dropdown-item href="/member-park">{{ $t('Member Park') }}</b-dropdown-item>
                </b-nav-item-dropdown>
              </li> -->
              <li>
                 <b-nav-item-dropdown class="border-bottom"  :text="$t('About')" v-bind:class="{ 'text-white': $route.path == '/'  }" right>
                  <b-dropdown-item><router-link :to="{name: 'ourHistory'}" exact tag='li' >{{ $t('Our History') }}</router-link></b-dropdown-item>
                  <b-dropdown-item><router-link :to="{name: 'ourTeam'}" exact tag='li' >{{ $t('Meet Our Team') }}</router-link></b-dropdown-item>
                  <b-dropdown-item><router-link :to="{name: 'membershipGuideline'}" exact tag='li' >{{ $t('Membership Guideline') }}</router-link></b-dropdown-item>
                  <b-dropdown-item><router-link :to="{name: 'faq'}" exact tag='li' >{{ $t('FAQ') }}</router-link></b-dropdown-item>
                  <b-dropdown-item><router-link :to="{name: 'contact'}" exact tag='li' >{{ $t('Contact') }}</router-link></b-dropdown-item>
                </b-nav-item-dropdown>
              </li>
                <li class="nav-item">
                <router-link :to="{name: 'standards'}" exact class="nav-link font-weight-500 border-bottom px-2" v-bind:class="{ 'text-white': $route.path == '/'  }">{{ $t('Standards') }}</router-link>
              </li>
              <li>
                <b-nav-item-dropdown class="border-bottom" v-bind:class="{ 'text-white': $route.path == '/'  }" :text="$t('Member Benefits')">
                <b-dropdown-item @click="onChageHash('#schools')"  href="/member-benefit#schools">{{ $t('Benefits for Schools') }}</b-dropdown-item>
                <b-dropdown-item @click="onChageHash('#teachers')" href="/member-benefit#teachers">{{ $t('Benefits for Teachers') }}</b-dropdown-item>
                </b-nav-item-dropdown>
              </li>
              <li class="nav-item">
                <router-link :to="{name: 'feeDetails'}" exact class="nav-link font-weight-500 border-bottom px-2" v-bind:class="{ 'text-white': $route.path == '/'  }">{{ $t('Fee') }}</router-link>
              </li>
              <li>
                <b-nav-item-dropdown :text="$t('Community')"  class="border-bottom" v-bind:class="{ 'text-white': $route.path == '/'  }">
                <b-dropdown-item><router-link :to="{name: 'videos'}" exact tag='li' >{{ $t('Videos') }}</router-link></b-dropdown-item>
                <b-dropdown-item><router-link :to="{name: 'exclusiveCenter'}" exact tag='li' >{{ $t('Exclusive Center') }}</router-link></b-dropdown-item>
                <b-dropdown-item><router-link :to="{name: 'wyaEvents'}" exact tag='li' >{{ $t('WYA Events') }}</router-link></b-dropdown-item>
                <b-dropdown-item><router-link :to="{name: 'memberPark'}" exact tag='li' >{{ $t('Yoga Stores') }}</router-link></b-dropdown-item>
                </b-nav-item-dropdown>
              </li>
              <div v-if="!loggedIn">
                <b-button pill variant="primary" :to="{ name: 'authenLogin' }" class="ml-2 mr-2 px-2 btn-auth btn-auth-login font-weight-bold">{{ $t('Login') }}</b-button>
                <b-button pill :to="{ name: 'authenSignup' }" class="px-2 btn-auth btn-auth-sign-up pl-0 pr-0 font-weight-bold">{{ $t('Sign up') }}</b-button>
              </div>
              <div v-else class="ml-5 ml-md-2" v-bind:class="{ 'text-white': $route.path == '/'  }">
                <span class="text-primary-dark">{{ $t('Hello') }}, </span>
                <span class="text-capitalize text-primary-dark show-user-fullname">{{ (userInfo.firstName ? userInfo.firstName + ' ' + (userInfo.lastName || '') : userInfo.username) }} </span>
                 <b-dropdown id="user-d-menu" class="py-0" :no-caret="true" right toggle-class="bg-transparent border-0 py-0">
                  <template slot="button-content">
                    <b-avatar v-if="userProfile" variant="light" :src="userProfile.profile !== undefined ? userProfile.profile.avatar : PUBLIC_PATH+'img/profiles/user-circle-regular.svg'"></b-avatar>
                  </template>
                  <router-link :to="{name: 'userProfile'}" class="d-block dropdown-item">{{ $t('Profile') }}</router-link>
                  <router-link :to="{name: 'changePassword'}" class="d-block dropdown-item">{{ $t('Change password') }}</router-link>
                  <b-dropdown-item-button class="text-gray-light justify-content-between d-none" style="font-size:14px">{{ $t('Notifications') }} <b-badge pill variant="danger" class="float-right mt-1 d-none">1</b-badge></b-dropdown-item-button>
                  <router-link :to="{name: 'schoolProfile'}" class="d-block dropdown-item">{{ $t('School Registration') }}</router-link>
                  <router-link :to="{name: 'teacherProfile'}" class="d-block dropdown-item">{{ $t('Teacher Registration') }}</router-link>
                  <router-link :to="{name: 'billing'}" class="d-block dropdown-item">{{ $t('Billing') }}</router-link>
                  <b-dropdown-divider></b-dropdown-divider>
                  <router-link :to="{name: 'authLogout'}" class="d-block dropdown-item">{{ $t('Log out') }}</router-link>
                </b-dropdown>
              </div>
            </b-navbar-nav>
          </b-col>
        </b-row>
      </b-collapse>
    </b-navbar>
  </scroll-fixed-header>
</template>

<script>
export default {
  name: 'Navbar',
  components: {},
  data () {
    return {
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
      instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
      youtubeUrl: process.env.VUE_APP_YOUTUBE_URL,
      iconHeader: typeof this.$route.meta.bgClass !== 'undefined' ? 'text-white' : 'header-text-icon',
      currentYear: new Date().getFullYear(),
      visible: false,
      fixed: false
    }
  },
  props: {
    navStyle: String
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    userInfo () {
      return this.$store.getters.userInfo
    },
    loggedIn () {
      return this.$store.getters.loggedIn
    }
  },
  async mounted () {
    if (this.$store.getters.loggedIn) {
      this.$store.dispatch('userInfo')
      this.$store.dispatch('userProfile')
    } else {
      if (this.$store.getters.clientCredentialToken == null) {
        await this.$store.dispatch('clientCredential')
      }
    }
  },
  watch: {
    fixed: function () {
      const slideContainer = document.querySelector('.slide-container')
      if (this.fixed === true) {
        if (slideContainer) {
          slideContainer.classList.add('scroll-fixed')
        }
      } else {
        if (slideContainer) {
          slideContainer.classList.remove('scroll-fixed')
        }
      }
    }
  },
  methods: {
    onChageHash (hash) {
      if (this.$route.name === 'memberBenefit') {
        const EventBus = this.$root
        EventBus.$emit('on-change-hash', hash)
      }
    }
    // toggleNavbarNavClass (addRemoveClass, className) {
    //   const nav = document.querySelector('.navbar-nav')
    //   if (addRemoveClass === 'addClass') {
    //     if (nav) {
    //       nav.classList.add(className)
    //     }
    //   } else {
    //     if (nav) {
    //       nav.classList.remove(className)
    //     }
    //   }
    // },
    // toggleNavlinkClass (addRemoveClass, className) {
    //   const navLink = document.querySelectorAll('.navbar-nav .nav-item a.nav-link')
    //   if (addRemoveClass === 'addClass') {
    //     navLink.forEach(function (item) {
    //       if (item) {
    //         item.classList.add(className)
    //       }
    //     })
    //   } else {
    //     navLink.forEach(function (item) {
    //       if (item) {
    //         item.classList.remove(className)
    //       }
    //     })
    //   }
    // }
  }
}
</script>
<style scope>
</style>
