<template>
  <body>
    <Navbar nav-style="authen"></Navbar>
    <transition :duration="300" origin="center top" mode="out-in">
      <router-view></router-view>
    </transition>
  </body>
</template>
<script>
import Navbar from '../../components/navbar/Navbar.vue'

export default {
  name: 'AuthLayout',
  components: {
    Navbar
  },
  mounted () {
    this.toggleNavbarClass('addClass', 'nav-text-white')
    document.querySelector('body#body-main').classList.remove('bm-overlay')
  },
  methods: {
    toggleNavbarClass (addRemoveClass, className) {
      const nav = document.querySelector('nav.navbar')
      if (addRemoveClass === 'addClass') {
        nav.classList.add(className)
      } else {
        nav.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleNavbarClass('removeClass', 'nav-text-white')
  }
}
</script>

<style lang="scss">
  .base-container {
    max-width: 1520px !important;
  }
  .bg-facebook {
    background: transparent linear-gradient(135deg, #4676ED 0%, #436DE4 19%, #3B54CD 49%, #2F2DA8 87%, #2B1E99 100%) 0% 0% no-repeat padding-box;
  }
  .input-auth {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #DBDBDB;
  }
</style>
