import BaseApi from './baseApi'
BaseApi.baseUrl = process.env.VUE_APP_API_ENDPOINT
BaseApi.apiTimeOut = process.env.VUE_APP_USER_API_TIMEOUT
const baseUrl = process.env.VUE_APP_API_ENDPOINT

export default {
  clientCredential (payload) {
    const data = {
      grant_type: 'client_credentials',
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      scope: 'read'
    }
    return BaseApi({ baseURL: baseUrl }).post('/api/auth/token/', data)
  },
  /**
   * Api เข้าสู่ระบบ ด้วย access token oauth2 google, facebook
   * @param {string} payload.username
   * @param {string} payload.password
   * @returns {object} Axios
   */
  authenWithSocial (payload) {
    payload.grant_type = 'convert_token'
    payload.client_id = process.env.VUE_APP_PERSONAL_CLIENT_ID
    return BaseApi({ baseURL: baseUrl }).post('/api/auth/convert-token', payload)
  },
  /**
   * Api เข้าสู่ระบบ
   * @param {string} payload.username
   * @param {string} payload.password
   * @returns {object} Axios
   */
  login (payload) {
    payload.grant_type = 'password'
    payload.client_id = process.env.VUE_APP_PERSONAL_CLIENT_ID
    return BaseApi({ baseURL: baseUrl }).post('/api/auth/token/', payload)
  },
  /**
   * logout
   * @returns {object} Axios
   */
  logout () {
    const payload = {
      client_id: process.env.VUE_APP_PERSONAL_CLIENT_ID,
      token: localStorage.getItem('accessToken')
    }
    return BaseApi({ baseURL: baseUrl }).post('/api/auth/revoke-token/', payload)
  },
  /**
   * user info
   * @returns {object} Axios
   */
  userInfo () {
    return BaseApi({ baseURL: baseUrl }).get('/api/user-auth/user/')
  },
  /**
   * user profile
   * @returns {object} Axios
   */
  userProfile () {
    return BaseApi({ baseURL: baseUrl }).get('/api/user-profile/')
  },
  /**
   * register new account
   * @param {string} payload.username
   * @param {string} payload.email
   * @param {string} payload.password1
   * @param {string} payload.password2
   */
  register (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/user-auth/registration/', payload)
  },
  /**
   * reset new password
   * @param {string} payload.email
   */
  resetpassword (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/user-auth/password/reset/', payload)
  },
  /**
   * confirm reset new password
   * @param {string} payload.new_password1 new password
   * @param {string} payload.new_password2 confirm new password
   * @param {string} payload.uid uid
   * @param {string} payload.token token for
   *
   * @returns {object} Axios
   */
  confirmResetpassword (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/user-auth/password/reset/confirm/', payload)
  },
  /**
   * Update user profile
   * @param {string} payload.email
   * @param {string} payload.first_name
   * @param {string} payload.last_name
   * @param {string} payload.profile.detail
   * @param {string} payload.profile.phone_number
   * @param {string} payload.profile.web_site_url
   * @param {number} payload.profile.region
   *
   * @returns {object} Axios
   */
  updateUserProfile (payload) {
    return BaseApi({ baseURL: baseUrl }).patch('/api/user-profile/', payload)
  },
  /**
   * search region location
   *
   * @param {string} payload.q text search
   * @returns {object} Axios
   */
  searchRegion (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/cities_light/regions/', { params: { q: payload.q } })
  },
  /**
   * Region location
   *
   * @param {string} payload.q text search
   * @returns {object} Axios
   */
  region (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/region', { params: payload })
  },
  /**
   * search country
   *
   * @param {string} payload.q text search
   * @returns {object} Axios
   */
  country (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/cities_light/countries/', { params: payload })
  },
  /**
   * Change password account
   *
   * @param {string} payload.password1
   * @param {string} payload.password2
   *
   * @returns {object} Axios
   */
  changePassword (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/user-auth/password/change/', payload)
  },
  /**
   * School form data master for register
   *
   * @returns {object} Axios
   */
  shoolFormData () {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/school-form-data/')
  },
  /**
   * Get result yoga school detail
   *
   * @param {int} payload.user filter by user id
   *
   * @returns {object} Axios
   */
  getSchool (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/school/' + payload.schoolId)
  },
  /**
   * Get result yoga school
   *
   * @param {int} payload.user filter by user id
   * @param {string} payload.search search school name
   *
   * @returns {object} Axios
   */
  listSchool (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/school', { params: payload })
  },
  /**
   * Register yoga school
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  createSchool (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/school/school', payload)
  },
  /**
   * Update yoga school
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  updateSchool (payload) {
    return BaseApi({ baseURL: baseUrl }).patch('/api/school/school/' + payload.id, payload)
  },
  /**
   * Update yoga school
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  updateFileSchool (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).patch('/api/school/school/' + payload.id, bodyFormData)
  },
  /**
   * Update file document yoga school
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  updateFileDocumentSchool (payload) {
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl }).post('/api/school/school/' + payload.id + '/document', bodyFormData)
  },
  /**
   * School comment
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  schoolComment (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/rating-and-comment', { params: payload })
  },
  /**
   * Add school comment
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  addSchoolComment (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/school/rating-and-comment', payload)
  },
  /**
   * School gallery list
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  schoolGallery (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/gallery', { params: payload })
  },
  /**
   * Delete School gallery
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  deleteSchoolGallery (payload) {
    return BaseApi({ baseURL: baseUrl }).delete('/api/school/gallery/' + payload.id)
  },
  /**
   * Update School gallery
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  updateSchoolGallery (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).patch('/api/school/gallery/' + payload.id, bodyFormData)
  },
  /**
   * Graduated list
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  graduated (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/graduate', { params: payload })
  },
  /**
   * Add graduated
   *
   * @param {object} payload
   * @param {int*} payload.school
   * @param {int} payload.user
   * @param {string*} payload.first_name
   * @param {string} payload.last_name
   * @param {string} payload.email
   * @param {string} payload.phone_number
   * @param {string} payload.website_url
   * @param {string} payload.facebook_url
   * @param {string} payload.twitter_url
   * @param {string} payload.instagram_url
   * @param {string*} payload.graduate_no
   * @param {string*} payload.graduate_at
   * @param {boolean} payload.is_active
   * @returns {object} Axios
   */
  addGraduated (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        if (data === 'teacher_level' || data === 'style_of_yoga' || data === 'teacher_apply_type') {
          const value = payload[data].map(v => v[data])
          bodyFormData.append(data, value.join())
        } else {
          bodyFormData.append(data, payload[data])
        }
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).post('/api/school/graduate', bodyFormData)
  },
  /**
   * Edit graduated
   *
   * @param {object} payload
   * @param {int*} payload.id
   * @param {int*} payload.school
   * @param {int} payload.user
   * @param {string*} payload.first_name
   * @param {string} payload.last_name
   * @param {string} payload.email
   * @param {string} payload.phone_number
   * @param {string} payload.website_url
   * @param {string} payload.facebook_url
   * @param {string} payload.twitter_url
   * @param {string} payload.instagram_url
   * @param {string*} payload.graduate_no
   * @param {string*} payload.graduate_at
   * @param {boolean} payload.is_active
   * @returns {object} Axios
   */
  editGraduated (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).patch('/api/school/graduate/' + payload.id, bodyFormData)
  },
  /**
   * Delete graduated
   * @param {object} payload
   * @param {*} payload
   *
   * @returns {object} Axios
   */
  deleteGraduated (payload) {
    return BaseApi({ baseURL: baseUrl }).delete('/api/school/graduate/' + payload.id)
  },
  /**
   * Event list
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  event (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/event', { params: payload })
  },
  /**
   * Add event
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  addEvent (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).post('/api/event', bodyFormData)
  },
  /**
   * Event detail
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  eventDetail (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/event/' + payload.id)
  },
  /**
   * Edit event
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  editEvent (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).patch('/api/event/' + payload.id, bodyFormData)
  },
  /**
   * Delete event
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  deleteEvent (payload) {
    return BaseApi({ baseURL: baseUrl }).delete('/api/event/' + payload.id)
  },
  /**
   * Rating comment summary
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  schoolRatingCommentSummary (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/rating-comment-summary/' + payload.schoolId)
  },
  /**
   * Add image gallery
   *
   * @param {object} payload
   */
  addImageGallery (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).post('/api/school/gallery', bodyFormData)
  },
  /**
   * Delete image gallery
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  deleteImageGallery (payload) {
    return BaseApi({ baseURL: baseUrl }).delete('/api/school/gallery/' + payload.id)
  },
  /**
   * Form data teacher
   *
   * @returns {object} Axios
   */
  formDataTeacher () {
    return BaseApi({ baseURL: baseUrl }).get('/api/teacher/teacher-form-data')
  },
  /**
   * Get teacher
   * @param {object} payload
   * @returns {object} Axios
   */
  teacher (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/teacher/teacher', { params: payload })
  },
  /**
   * Get teacher detail
   * @param {object} payload
   * @returns {object} Axios
   */
  teacherDetail (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/teacher/teacher/' + payload.id)
  },
  /**
   * Get teacher
   * @param {object} payload
   * @returns {object} Axios
   */
  createTeacher (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/teacher/teacher', payload)
  },
  /**
   * Update teacher
   * @param {object} payload
   * @returns {object} Axios
   */
  updateTeacher (payload) {
    return BaseApi({ baseURL: baseUrl }).patch('/api/teacher/teacher/' + payload.id, payload)
  },
  /**
   * Update teacher document
   * @param {object} payload
   * @returns {object} Axios
   */
  updateTeacherDocument (payload) {
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl }).post('/api/teacher/teacher/' + payload.id + '/document', bodyFormData)
  },
  /**
   * Teacher gallery list
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  teacherGallery (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/teacher/gallery', { params: payload })
  },
  /**
   * Add image gallery
   *
   * @param {object} payload
   */
  addImageTeacherGallery (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).post('/api/teacher/gallery', bodyFormData)
  },
  /**
   * Delete teacher gallery
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  deleteTeacherGallery (payload) {
    return BaseApi({ baseURL: baseUrl }).delete('/api/teacher/gallery/' + payload.id)
  },
  /**
   * Update teacher gallery
   *
   * @param {object} payload
   *
   * @returns {object} Axios
   */
  updateTeacherGallery (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).patch('/api/teacher/gallery/' + payload.id, bodyFormData)
  },
  /**
   * Get result testimonials
   *
   * @returns {object} Axios
   */
  listTestimonial (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/testimonial')
  },
  /**
   * Get result contact
   *
   * @returns {object} Axios
   */
  addContact (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/contact', payload)
  },
  /**
   * Get invoice list
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  invoice (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/invoice', { params: payload })
  },
  /**
   * Get invoice detail
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  invoiceDetail (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/invoice/' + payload.invoiceId)
  },
  /**
   * Get order detail
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  orderDetail (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/order/' + payload.orderId)
  },
  /**
   * Make payment invoice
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  makePayment (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/payment/make-payment', payload)
  },
  /**
   * Outstanding amount
   *
   * @param {object} payload
   * @param {string} payload.currency
   * @returns {object} Axios
   */
  outStanding (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/invoice-outstanding', { params: payload })
  },
  /**
   * Get invoice address
   *
   * @param {*} payload
   * @param {int} user
   *
   * @returns {object} Axios
   */
  getInvoiceAddress (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/invoice-address', { params: payload })
  },
  /**
   * Add invoice address
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  addInvoiceAddress (payload) {
    return BaseApi({ baseURL: baseUrl }).post('/api/invoice-address', payload)
  },
  /**
   * Add invoice address
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  updateInvoiceAddress (payload) {
    return BaseApi({ baseURL: baseUrl }).patch('/api/invoice-address/' + payload.id, payload)
  },
  /**
   * Payment transaction list
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  paymentTransaction (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/payment-transaction', { params: payload })
  },
  /**
   * Update invoice
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  updateInvoice (payload) {
    return BaseApi({ baseURL: baseUrl }).patch('/api/invoice/' + payload.id, payload)
  },
  /**
   * Video list
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  video (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/video', { params: payload })
  },
  /**
   * Video detail
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  videoDetail (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/video/' + payload.id)
  },
  /**
   * Add Payment transaction
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  addPaymentTransaction (payload) {
    const headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': localStorage.getItem('language') || 'en'
    }
    const bodyFormData = new FormData()
    for (const data in payload) {
      if (payload[data]) {
        bodyFormData.append(data, payload[data])
      }
    }
    return BaseApi({ baseURL: baseUrl, headers: headerConfig }).post('/api/payment-transaction', bodyFormData)
  },
  /**
   * Get school marker
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  schoolMarker (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/school/marker', { params: payload })
  },
  /**
   * Get teacher marker
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  teacherMarker (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/teacher/marker', { params: payload })
  },
  /**
   * Get event marker
   *
   * @param {*} payload
   * @returns {object} Axios
   */
  eventMarker (payload) {
    return BaseApi({ baseURL: baseUrl }).get('/api/event/marker', { params: payload })
  }
}
